.sun__core {
  transform: translate3d(0, 0, 0) scale(1); // add this in not light
  transition: transform .4s $in-out-quad .15s;

  @include dark() {
    transform: translate3d(0, 0, 0) scale(.3);
    transition: transform .6s $out-quad .5s;
  }
}

.sun__beam {
  transform: translate3d(0, 0, 0) scale(1);
  transition: transform .35s $out-back .5s;

  @include dark() {
    transform: translate3d(0, 0, 0) scale(.1);
    transition: transform .6s $out-quad .5s;
  }

  @include light() {
    @include transition-split(8, .1, .4);
  }
}
