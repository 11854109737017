.star-svg {
  stroke: currentColor;
  fill: currentColor;

  transition: .15s cubic-bezier(.455,.03,.515,.955);
  transition-property: fill, stroke;

  &:not(.star-svg--plain) {
    fill: transparent;
  }
}
