.theme__btn {

  &:focus,
  &:hover {

    & + .theme__name {
      transition-duration: .6s;

      opacity: 1;
    }
  }
}

.theme__btn__inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  size: 4rem;
}
