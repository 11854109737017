.notification__btn {
  position: relative;
  padding: .9rem 3rem;

  background: v(accent);
  border-color: v(accent);
  border-radius: .8rem;
  color: v(bg);

  font-size: initial;

  &:hover,
  &:focus {
    color: v(bg);

    &::before {
      opacity: .5;
    }
  }

  &:not(:hover):not(:focus) {

    .btn__icon {
      transform: rotate(-45deg);
    }
  }

  &:active {
    transform: translate3d(0, .1em, 0);
    transition: transform .1s $in-out-quad;

    &::before {
      opacity: 1;
      transform: translate3d(0, -.1em, 0) scale(.92);
    }

    .btn__icon {
      animation: spin-20-turn 2.5s infinite;
    }
  }

  &::before {
    content: '';

    position: absolute;
    inset: .2rem;
    z-index: -1;

    border-radius: .8rem;
    box-shadow: 0 .5rem 2rem .5rem v(accent);
    opacity: 0;

    transition: opacity .15s $in-out-quad;
    transition-property: opacity, transform;
  }
}

.notification__btn__icon {
  transition: transform .3s $out-quad;
  transform-origin: 60% 50%; // due to the arrow, the center is slightly shifted from the circle center
}
