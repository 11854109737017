.notification__message {
  transform: translate3d(0, 1em, 0);

  opacity: 0;

  animation: appear-in .5s $in-out-quad .5s forwards, opacity .5s $in-out-quad .5s forwards;
  @include animation-split(4, .3, .5)
}

.notification__detail {
  margin: .3em auto 2em;
}
