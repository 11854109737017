.btn-group {

  @media (--smallest) {
    display: inline-flex;
  }
}

.btn--grouped {
  padding: .7rem 1rem;

  @include all-but(1) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  @include all-but(last) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
