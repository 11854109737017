@media (--smallest) {

  .sticky-regions-and-sorters {

    .regions-count__arrow {
      --region-count-arrow-y: 0;

      transition-duration: .7s;
      transition-delay: 0s, .3s, .3s; // `0s`: color on hover
    }

    .regions-count__not-shown {
      visibility: hidden;
    }

    .regions__sorters {
      transform: translate3d(0, -3.4rem, 0);
    }

    .sorters__title {
      visibility: hidden;
    }
  }

  html:not(.sticky-regions-and-sorters) {

    .regions-count__arrow {
      opacity: 0;
    }
  }
}
