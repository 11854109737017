.filters__btn {
  position: relative;
  z-index: 1;

  flex-shrink: 0;
  size: 4.4rem;

  font-size: 0;

  transition: color .15s $in-out-quad;

  @media (--to-smallest) {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;

    border-radius: .9rem;
    @include border(currentColor, .2rem);

    transition: opacity .15s $in-out-quad;
  }

  &:focus {
    color: v(accent);
  }

  &:not(:hover):not(:focus) {

    &::before {
      opacity: 0;
    }
  }
}

.filters__btn--open {
  top: .2rem;
}

.filters__btn--close {
  position: absolute;
  inset: 1.3rem 1.2rem unset unset;
}
