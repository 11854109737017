$bp-temp: (
  'temp-design-warning': 60em,
);

@include --w($bp-temp);

.temp-wrapper-size {
  max-width: v(temp-max-width);

  @supports (top: #{'max(0px)'}) {
    margin-left: v(safe-left-0);
    margin-right: v(safe-right-0);
  }

  @media (--temp-design-warning) {
    margin-left: v(temp-margin-left);
    margin-right: v(temp-margin-right);
  }
}

#app {
  --temp-max-width: 60rem;
  --temp-margin-left: 10%;
  --temp-margin-right: auto;

  position: relative;

  &::after {

    @media (--temp-design-warning) {
      content: '👈 Temporary design, better on small screens. / Saves your favorite region. / Works offline. / Still under development.';

      position: fixed;
      inset: unset 0 0 calc(62rem + 10%);
      padding: 1em;

      font-size: 1.6em;
      text-indent: -1.3em;
    }
  }
}

.scrollblock {
  overflow: hidden;
}
