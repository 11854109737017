.region {
  padding: 1rem 0;

  line-height: 1.3;

  @media (--smallest) {
    display: flex;
    align-items: center;
  }

  @include all-but(1) {
    @include border-top(v(grey));
  }
}

.region__code {
  flex: 0 0 3rem;
  color: v(grey);

  font-size: 1.3rem;
}

.region__name {
  flex: 1;
  flex-flow: wrap;
}

.region__translatedName {
  display: block;
  color: v(grey);
}

.region__usage {
  flex: 0 0 4.4rem;

  text-align: right;
  font-variant-numeric: tabular-nums;
}

.region__star {
  margin-inline: .1rem;

  flex: 0 0 4rem;
  height: 4rem;

  transition: color .15s $in-out-quad;

  @media (--smallest) {
    margin-inline-start: 2.4rem;
  }

  &:focus,
  &:hover {
    color: v(accent);
  }
}
