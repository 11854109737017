.header {

  @media (--smallest) {
    padding: 2rem v(x-space) 3.3rem;
  }
}

.header__row {

  @media (--smallest) {
    display: flex;
    justify-content: space-between;
  }
}

.header__end {
  flex-wrap: wrap;
  justify-content: flex-end;

  > * {
    margin-inline-start: 1rem;
  }
}
