.fillable__input {
  padding: .9rem 1.5rem;

  color: v(accent);

  transition: opacity .3s $in-out-quad;

  &::placeholder {
    color: v(accent);

    font-weight: 400;

    transition: inherit;
  }

  &:focus {
    outline: none;

    &::placeholder {
      opacity: .5;
    }
  }

  &:not(:focus):not(:hover) {

    &:placeholder-shown {
      opacity: .8;
    }

    + .fillable__bg {

      &::before {
        opacity: 0;
      }
    }
  }
}
