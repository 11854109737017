@media (--smallest) {
  .sorters {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > :not(:first-child) {
      margin-left: 1rem;
    }
  }
}
