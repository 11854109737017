.nowrap {
  white-space: nowrap;
}

.truncated-text {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
