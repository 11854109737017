@media (--smallest) {

  .threshold__label {
    margin-right: 2rem;
  }

  .threshold__label__suffix {
    order: 1;
    margin-left: .5em;
  }
}
