.browsers__list {
  padding: 3rem 0;
}

.browser__ctn {}

.browser__item {
  position: relative;
  padding: 1rem 0;

  display: grid;
  grid-template-columns: max-content auto;
  gap: 2rem;
  align-items: baseline;

  &:not(.browser__item--date-visible):not(.browser__item--selected):not(:hover) {
    .browser__date {
      opacity: 0;
    }
  }
}

.browser__item--selected {
  .browser__label,
  .browser__status {
    color: v(accent);
  }
}

.browser__version {
  padding: 1rem 0;
  grid-template-columns: 6.5rem v(longest, auto) 1fr;
}

.browser__year {}

.browser__label {
  width: 100%;
  padding-top: .5em;
  padding-bottom: .5em;

  display: block;

  line-height: 1.2;
}

.browser__input {
  position: absolute;
  inset: 0 unset unset 0;
  z-index: 1;

  size: 100%;

  cursor: pointer;

  &:not(:checked):not(:hover):not(:focus) {

    + .browser__name {
      --transition-duration: .6s;
      --transition-easing: #{$in-out-quad};

      transform: translate3d(-.65em, 0, 0);

      color: v(primary);
    }
  }

  &:not(:checked) {

    + .browser__name {

      &::before {
        opacity: 0;
      }
    }
  }
}

.browser__name {
  --transition-duration: .3s;
  --transition-easing: #{$out-back};

  display: block;

  pointer-events: none;

  color: v(accent);

  transition: transform v(transition-duration) v(transition-easing);

  &::before {
    content: '• ';

    transition: opacity v(transition-duration) v(transition-easing);
  }
}

.browser__status {
  margin: 0;
  display: grid;

  font-size: 100%;
}

.browser__date {
  color: v(grey);

  transition: opacity .15s $in-out-quad;
}
