.threshold__controls {

  @media (--smallest) {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
  }
}

.threshold__btn {
  size: 2.8rem;

  border-radius: 50%;
  @include border(currentColor);

  font-size: 1.8rem;

  transition: .15s $in-out-quad;
  transition-property: border-color, color;

  @include all-but(1) {

    @media (--smallest) {
      margin-left: 1em;
    }
  }

  &:focus,
  &:hover {
    border-width: .2rem;
    color: v(accent);
  }
}

.threshold__btn__label {

  &::before {
    content: attr(css-label);
  }
}
