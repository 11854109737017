.regions-count {
  margin-bottom: 1.5rem;

  overflow: hidden;

  display: flex; // required for inline child transform
  align-items: baseline;

  @media (--smallest) {
    position: sticky;
    top: 0;
    z-index: 1;

    padding: 1.5rem v(x-space) .8rem;

    pointer-events: none;
  }
}

.regions-count__shown {

  @media (--smallest) {
    pointer-events: auto;

    text-decoration: none;

    transition: transform .4s $out-quint;
  }

  &:hover {
    outline: none;

    ~ .regions-count__arrow {
      color: v(accent);
    }
  }

  &:active {

    ~ .regions-count__arrow {
      --region-count-arrow-y: -10%;

      transition-duration: .1s;
      transition-delay: 0s;
      transition-timing-function: $in-out-quad;
    }
  }
}

.regions-count__not-shown {
  font-weight: 400;
  font-size: 1.3rem;
}

.regions-count__arrow {
  display: none;

  @media (--smallest) {
    position: absolute;
    top: 2.9rem;
    left: v(x-space);
    transform: translate(-.6em, v(region-count-arrow-y, 100%));

    display: initial;

    color: v(grey);

    transition: .4s $out-quint;
    transition-property: color, opacity, transform;
  }
}
