.footer {

  &:target {
    position: sticky;
    z-index: 1;
    bottom: 0;

    max-width: v(temp-max-width);
    max-height: 80vh;
    padding-bottom: 3.3rem;
    overflow: auto;

    background: v(bg);
    box-shadow: 0 0 1.5rem v(footer-shadow-spread, -.3rem) hsl(0 0% 0% / .1);

    @include dark() {
      --footer-shadow-spread: .7rem;
    }

    .footer__close {
      display: inline-block;
    }
  }

  @media (--smallest) {
    padding: 3.3rem v(x-space) 9rem;
  }
}

.footer__close {
  display: none;

  position: sticky;
  top: 0;
  margin-inline-start: auto;
  margin-block-end: 2rem;

  background: v(bg);
}
