.btn {
  padding: .6rem;

  @include border(v(primary), .2rem);
  border-radius: .5rem;

  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1;
  text-decoration: none;

  transition: .15s $in-out-quad;
  transition-property: background-color, border-color, color;

  &:focus,
  &:hover {
    border-color: v(accent);
    color: v(accent);
  }
}

.btn--selected {
  background: v(primary);
  color: v(bg);

  &:focus,
  &:hover {
    background: v(accent);
    color: v(bg);
  }
}

.btn--no-border {
  border-color: transparent;

  &:focus,
  &:hover {
    border-color: transparent;
  }
}
