.fillable__bg {
  --input-radius: .8rem;

  position: absolute;
  inset: -.1rem unset unset -.1rem;
  z-index: -1;

  size: calc(100% + .2rem);

  background: v(btn-bg);
  border-radius: v(input-radius);
  border-width: .1rem;
  @include border(v(accent));

  pointer-events: none;

  transition: opacity .3s $in-out-quad;

  &::before {
    content: '';

    position: absolute;
    inset: -.2rem unset unset -.2rem;

    size: calc(100% + .4rem);

    border-radius: calc(var(--input-radius) + .1rem);
    @include border(v(accent));

    transition: inherit;
  }
}
