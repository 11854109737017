/**
 * BORDERS AND OUTLINES
 *
 * Because border and outline colors changes
 * more often than their width and style.
 *
 * Use:
 *  @include border(#123456);
 *  @include border(#123456, .1rem);
 *  @include border(#123456, .1rem, dotted);
 *  @include border-right(#123456);
 *  @include outline(#123456);
 *
 * Parameters:
 *  $color
 *  $w (1px by default)
 *  $style ('solid' by default)
 *
 * Improvments:
 *  Check for outline-offset.
 */

@mixin border($color, $w: '1px', $style: 'solid') {
  border: #{$w} #{$style} #{$color};
}

@mixin border-right($color, $w: '1px', $style: 'solid') {
  border-right: #{$w} #{$style} #{$color};
}

@mixin border-top($color, $w: '1px', $style: 'solid') {
  border-top: #{$w} #{$style} #{$color};
}

@mixin border-bottom($color, $w: '1px', $style: 'solid') {
  border-bottom: #{$w} #{$style} #{$color};
}

@mixin border-left($color, $w: '1px', $style: 'solid') {
  border-left: #{$w} #{$style} #{$color};
}

@mixin outline($color, $w: '1px', $style: 'solid', $offset: '') {
  outline: #{$w} #{$style} #{$color};

  @if $offset != '' {
    outline-offset: $offset;
  }
}
