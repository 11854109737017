.notification {

  @media (--smallest) {
    position: fixed;
    bottom: 0;
    transform: translate3d(0, 120%, 0);
    z-index: 2;

    padding: 2.3rem v(x-space) 3.2rem;
    width: 100%;
    max-width: v(temp-max-width);
    flex-direction: column;

    background: v(bg);
    @include border-top(v(accent), .4rem);
    color: v(accent);

    animation: appear-in 1s $out-quint .5s forwards;

    @supports (top: #{'max(0px)'}) {
      padding-bottom: #{'max(var(--safe-bottom), 3.2rem)'};
    }
  }

  &::before {

    @media (--smallest) {
      content: '';

      position: absolute;
      inset: 0;
      z-index: -1;

      box-shadow: -.5rem 0 3rem v(accent);
      opacity: .4;
      pointer-events: none;
    }
  }
}
