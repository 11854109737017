@media (--smallest) {

  .filters {
    position: fixed;
    z-index: 1;
    bottom: 0;

    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: v(temp-max-width);

    pointer-events: none;

    &::before,
    &::after {
      content: '';

      position: absolute;
      inset: 0 unset unset 0;
      z-index: -1;

      size: 100%;

      pointer-events: none;

      transition: transform .3s $out-quad;
    }

    // Background
    &::before {
      background: v(bg);
    }

    // Border and shadow
    &::after {
      @include border-top(v(primary), 0.1rem);
      box-shadow: 0 0 .5rem 0 $black-russian;

      opacity: .2;

      transition-property: opacity, transform;
    }
  }

  .filters--visible {

    .filter-txt__label {
      transition-duration: .3s;
      transition-delay: .18s;
    }
  }

  .filters:not(.filters--visible) {

    &::before,
    &::after {
      transform: translate3d(0, calc(100% - 7rem), 0);
      transition-duration: 0.4s;
      transition-timing-function: $out-quad;
    }

    &::after {
      opacity: 0;
    }

    .filter-txt__label {
      opacity: 0;
      visibility: hidden;
      transition-delay: 0s;
      transition-timing-function: $out-quint;
    }

    .filters__collapsed {
      transform: translate3d(0, calc(100% + 2.5rem), 0);
      opacity: 0;

      transition-duration: 0.4s;
      transition-timing-function: $out-quad;
    }
  }
}
