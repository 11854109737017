@media (--smallest) {

  .browsers-toggle-btn {
    margin-top: 3rem;
  }

  .browsers-ctn {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  .browsers {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    width: 100%;
    max-width: v(temp-max-width);
    height: 100%;

    padding: 2rem v(x-space) v(safe-bottom);

    display: grid;
    grid-template-rows: auto 1fr;
    gap: 2rem;

    background: v(bg);

    @supports (top: #{'max(0px)'}) {
      left: v(safe-left-0);
      padding-top: #{'max(var(--safe-top), 2rem)'};
    }

    @media (--temp-design-warning) {
      margin-left: v(temp-margin-left);
      margin-right: v(temp-margin-right);
    }
  }
}
