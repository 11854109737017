@media (--smallest) {

  .browsers__form {
    display: grid;

    overflow: hidden;
  }

  .browsers__form--with-button {
    padding-bottom: 2rem;
    grid-template-rows: 1fr auto;
  }

  .browser__fieldset {
    overflow-y: auto;
  }

  .browser__fieldset-inner {
    display: flex;
  }

  .browser__group {
    flex: 1 0 50%;
  }

  .browser__group-title {
    position: sticky;
    z-index: 1;
    top: 0;

    background: v(bg);
  }
}
