@media (--smallest) {

  .filters__sticky {
    position: relative;
    z-index: 1;
    order: 1;

    padding: 1rem v(x-space) 1.7rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    pointer-events: auto;

    @supports (top: #{'max(0px)'}) {
      padding-bottom: #{'max(var(--safe-bottom), 1.7rem)'};
    }

    // Panel in front of collapsed filters, hiding them.
    &::before {
      content: '';

      position: absolute;
      inset: unset unset 0 0;
      z-index: -1;

      width: 100%;
      height: 5.9rem; // padding-bottom 1.7 + input height 4.2

      pointer-events: none;

      background: v(bg);
    }
  }
}
