.sorter__arrow {
  transition: transform .3s ease;

  &:last-of-type {
    margin-left: .1rem;
  }
}

// Asc and desc arrows: without scale (or rotate), their sizes are different.

.sorter__arrow--asc {
  transform: scaleY(-1) rotate(180deg);

  .sorter--asc & {
    transform: translate3d(50%, 0, 0) scale(1.2, -1.2) rotate(180deg);
  }

  .sorter--desc & {
    transform: translate3d(50%, 0, 0) scale(1.2, -1.2);
  }
}

.sorter__arrow--desc {
  transform: scaleY(-1);

  .sorter--selected & {
    transform: scale(0);
  }
}
