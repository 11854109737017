@media (--smallest) {

  .browsers__nav {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    gap: 1rem;
  }

  .browsers__back {
    margin-left: -2rem;
    padding-right: 1.3rem;
    padding-left: 2rem;

    &:hover,
    &:focus {
      --arrow-x: -.2rem;
    }

    &:active {
      --arrow-x: -.6rem;
      --duration: .8s;
      --timing: var(--out-expo);
    }
  }

  .back__arrow {
    line-height: 0;

    transform: translateX(v(arrow-x, 0)) rotate(-90deg);
    transition: transform v(duration, .3s) v(timing, $in-out-quad);
  }
}
