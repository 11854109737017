.theme__icon__ctn {
  position: relative;
  size: 4rem;
}

.theme__icon {
  position: absolute;
  inset: 50% unset unset 50%;

  display: inline-flex;

  pointer-events: none;
}

// ☾
.theme__icon--dark {
  transform: translate3d(0%, -150%, 0) rotate(20deg) scale(0);
  transition: transform .25s $in-out-quad;

  @include dark() {
    transform: translate3d(-50%, -50%, 0) rotate(0deg) scale(1);
    transition-duration: 1.6s;
  }
}

// ☀️
.theme__icon--light {
  transform: translate3d(-50%, -50%, 0) scale(1);
  transition: transform .6s $out-quad;

  @include dark() {
    transform: translate3d(-50%, calc(4rem - 50%), 0) scale(0);
    transition: transform .8s $in-quad;
  }
}
