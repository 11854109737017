.h {
  font-weight: 500;
}

.h1 {
  font-size: 2.4rem;
}

.h2 {
  font-size: 2.2rem;
}
