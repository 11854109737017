@media (--smallest) {

  .selected-only {
    display: flex;
    align-items: center;
  }

  .selected-only__btns {
    margin-left: 2rem;
  }
}
