.threshold__input {
  width: 2.4em;

  text-align: right;
  font-weight: 700;
  font-variant-numeric: tabular-nums;

  transition: color .15s $in-out-quad;

  &:focus,
  &:hover {
    color: v(accent);
  }

  // Remove browser styles of input[type="number"] controls.
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
