.filter-txt {
  position: relative;
  display: flex;
  flex-direction: column;
}

.filter-txt__label {
  margin-bottom: 0.5rem;

  transition: .15s $in-out-quad .15s;
  transition-property: opacity, visibility;
}

.filter-txt__input {
  width: 100%;
}
