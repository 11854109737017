.theme {
  position: relative;

  @media (--smallest) {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus-within {

    .theme__btn {
      color: v(accent);
    }
  }
}
