.filters__collapsed {

  @media (--smallest) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    padding: 1rem v(x-space) 1.7rem;

    pointer-events: auto;

    transition: .3s $in-out-quad;
    transition-property: opacity, transform;
  }
}

.filter {
  flex-grow: 1;

  &:not(.filter--narrowed) {

    &:nth-of-type(1) {
      margin-top: 1rem;
    }

    @include all-but(1) {
      margin-top: 3.7rem;
    }
  }
}

.filter--narrowed {

  @media (--smallest) {
    margin-right: 1.4rem;
  }
}

.filter__label {
  margin: 0;
}
