.regions__sorters {

  @media (--smallest) {
    position: sticky;
    top: 3.4rem;

    padding-top: 1rem;

    background: v(bg);

    transition: transform .15s $out-quad;

    &::before {
      content: '';

      position: absolute;
      inset: unset unset -.7rem 0;
      z-index: -1;

      size: 100% calc(100% + 1.2rem);

      background: v(bg);
    }
  }
}
