:root {

  /* Colors */

  --primary: #{$light-primary};
  --bg: #{$light-bg};
  --btn-bg: #{$light-btn-bg};
  --accent: #{$light-accent};

  --grey: #{$jumbo};

  @include dark() {
    --primary: #{$dark-primary};
    --bg: #{$dark-bg};
    --btn-bg: #{$dark-btn-bg};
    --accent: #{$dark-accent};
  }

  @include light() {
    --primary: #{$light-primary};
    --bg: #{$light-bg};
    --btn-bg: #{$light-btn-bg};
    --accent: #{$light-accent};
  }

  /* Length */

  --x-space: 1.5rem;

  --safe-top: var(--safe-area-top, 0);
  --safe-right: var(--safe-area-right, var(--x-space));
  --safe-bottom: var(--safe-area-bottom, 0);
  --safe-left: var(--safe-area-left, var(--x-space));

  --safe-right-0: var(--safe-area-right-0, 0);
  --safe-left-0: var(--safe-area-left-0, 0);

  @supports (top: #{'max(0px)'}) {
    --safe-area-top: env(safe-area-inset-top, 0);
    --safe-area-right: max(env(safe-area-inset-right), var(--x-space));
    --safe-area-bottom: env(safe-area-inset-bottom, 0);
    --safe-area-left: max(env(safe-area-inset-left), var(--x-space));

    --safe-area-right-0: max(env(safe-area-inset-right), 0px);
    --safe-area-left-0: max(env(safe-area-inset-left), 0px);
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @media (--reduced-motion) {
    transition: 0.001s !important;
    animation: 0.001s !important;
  }
}

* {
  touch-action: manipulation;
}

::selection {
  background: v(accent);
  color: v(bg);
}

/* HTML & BODY */

html {
  size: 100%;

  background: v(bg);
  color: v(primary);

  font: 500 50% / #{$body-lh} $body-font;
  @include font-smoothing(on);

  @media (--smallest) {
    font-size: 62.5%;
  }
}

body {
  size: 100%;
  min-height: 100%;

  font-size: $body-size;

  // @media (--mobile) {
  //   overflow-x: hidden;
  // }
}

/* LISTS */

ul,
ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

dl,
dd {
  margin: 0;
}

/* QUOTES */

blockquote {
  margin: 0;
}

/* IMAGES */

figure {
  margin: 0;

  font-size: 0;
}

// img {

//   position: relative;
//   z-index: 1;
//   display: block;
//   size: 100% auto;

//   &::selection {
//     background: v(accent);
//   }

//   // Not loading images.
//   &::after {
//     content: '\0274f' ' ' attr(alt);
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     size: 100%;
//     padding: 0 1em;

//     color: $jumbo;
//     background: lighten($jumbo, 21%);

//     font-size: $body-size;
//   }
// }

/* FORMS */

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

input {
  appearance: none;
  border-radius: 0; // Safari iOS

  line-height: inherit;

  // // Safari MacOS contact icon.
  // &::-webkit-contacts-auto-fill-button {
  //   display: block !important;
  //   transform: translate3d(100%, 0, 0);

  //   background-color: v(accent);
  //   opacity: 0;
  // }

  // &:focus,
  // &:hover {

  //   &::-webkit-contacts-auto-fill-button {
  //     display: block !important;
  //     opacity: 1;
  //     transform: translate3d(0, 0, 0);

  //     transition: .65s $in-out-circ .05s;
  //     transition-property: opacity, transform;

  //     &:hover {
  //       transform: translate3d(0, 0, 0) scale(1.1);

  //       transition-duration: .4s;
  //       transition-delay: 0;
  //     }
  //   }
  // }
}

// select {
//   padding-left: 0;
//   padding-right: 0;
// }

// option {
//   padding-block-start: 0;
//   padding-inline-start: 0;
//   padding-block-end: 0;
//   padding-inline-end: 0;
// }

input,
select {

  // Autofill colors hack
  &:-webkit-autofill {
    -webkit-text-fill-color: v(primary);
    background-color: transparent;
    box-shadow: 0 0 0px 100px transparent inset;
    transition: background-color 500000s ease-in-out 500000s;

    &::selection {
      -webkit-text-fill-color: v(accent);
    }
  }
}

input,
button {
  padding: 0;

  background: transparent;
  border: none;
  color: v(primary);

  font-weight: 500;

  &:focus {
    outline: none;
  }
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;

  * { pointer-events: none; }
}

/* ABBREVIATIONS */

abbr[title] {

  &:not(:hover) {
    border: none;
    text-decoration: none;
  }
}

/* LINKS */

a {
  color: v(primary);

  transition: color .15s $in-out-quad;

  &:focus,
  &:hover {
    color: v(accent);

    @include outline(v(accent), .1rem, dashed, .3rem);
    text-decoration: none;
  }
}

/* TITLES */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;

  em {
    font-style: normal;
  }
}

/* VARIOUS */

hr {
  max-width: 20em;
  margin-block: 1.5em;
  border-bottom: none;
}
svg {
  pointer-events: none;

  * { transform-origin: 50% 50%; }
}
