.fillable {
  position: relative;

  transition: filter .3s $in-out-quad;

  &:not(:focus-within) {
    filter: grayscale(1);
  }
}

