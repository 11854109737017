.filter {
  transition: opacity .15s $in-out-quad;
}

.filter--disabled {
  opacity: .4;

  &:not(:hover):not(:focus-within) {
    opacity: .2;
  }
}
